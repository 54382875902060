<template>
    <div>
        <fromSearch :object_='seriesList_' @searchFun='getList' ref='fromSearch_'  />
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange" >
            <template #menu-left>
                <el-button type="primary" @click="downBlobFileFun(fromSearch_.returnParm(),page)">导出</el-button>
                <el-button type="primary" @click="refreshFun">刷新交易</el-button>
            </template>
            <template #confirmTime="scope">
                {{turnTimeFun(scope.row.confirmTime)}}
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #callbackTime="scope">
                {{turnTimeFun(scope.row.callbackTime)}}
            </template>
            <template #menu="scope">
                <el-button text type="primary" size="small" @click="callbackFun(scope.row)">回调</el-button>
            </template> 
        </avue-crud>   
        <el-dialog title="提示" v-model="disShow" width="30%">
            <avue-form :option="option" v-model="form" @submit="submitFun" ref="formAvue"></avue-form>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref } from 'vue'
    import { topUp_ } from '@/const/from/record/customerWallet.js'
    import fromSearch from '@/components/fromSearch.vue'
    import { topUp } from '@/const/crud/record/customerWallet'
    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['getTopUpUser'])
    import { turnTimeFun } from '@/utils/util.js'
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    
    import mixins1 from '@/views/mixins/common'
    let commonFun = mixins1('topUp_');const { downBlobFileFun } = commonFun
    const fromSearch_ = ref(null)

    const tableOption = ref({}); tableOption.value = topUp
    const seriesList_ = ref(topUp_);
    const disShow = ref(false)
    const form = ref({})
    const formAvue = ref(null)
     const option = ref({
        column: [
            {
                label: '链',
                prop: 'chain',
                span: 24,
                type: 'select',
                dicUrl: `/chain/chaininfo/page?current=1&size=1000`,
                dicFormatter: (res)=>{
                    return res.records
                },
                props: {
                    label: 'chain',
                    value: 'chain'
                },
                rules: [{
                    required: true,
                    message: "请选择",
                    trigger: "blur"
                }]
            },
            {
                label: 'TXID',
                prop: 'txId',
                span: 24,
                rules: [{
                    required: true,
                    message: "请输入",
                    trigger: "blur"
                }]
            }
        ]
     })
    getList(1)
    // import { userStore } from '@/store/user.js'
    // const getButtonArray_ = ref(userStore());
    // const isShow = (str)=>{
    //     if(getButtonArray_.value && getButtonArray_.value.getButtonArray && Array.isArray(getButtonArray_.value.getButtonArray)){
    //         return getButtonArray_.value.getButtonArray.includes(str)
    //     }else{
    //         return false
    //     }
    // }
    const callbackFun = (scope)=>{
        listLoading.value = true
        Api_.getTopUpUserCall(scope.recordId).then(res=>{
            listLoading.value = false
            if(res.code === 0){
                ElNotification.success('回调成功')
            }else{
                ElNotification.error(res.msg)
            }
        })
    }
    const refreshFun = ()=>{
        disShow.value = true
        if(formAvue.value){
            formAvue.value.resetForm()
        }
    }
    const submitFun = (form1, done)=>{
        done()
        Api_.refreshTx(form.value).then(res=>{
            disShow.value = false
            if(res.code === 0){
                ElNotification.success('操作成功')
            }else{
                ElNotification.error(res.msg)
            }
        })
    }
</script>

<style lang="scss" scoped>
    :deep(.el-dialog__header){
        margin-bottom: 0;
    }
    :deep(.el-dialog__body){
        padding-top: 10px;
    }
</style>